/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Apt-file es un programa muy interesante cuando estas compilando algo y tienes problemas con las librerías. Si quieres saber en que paquete esta determinado archivo este es tu programa. Lo instalamos así de fácil:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">aptitude</span> <span class=\"token function\">install</span> apt-file</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y para buscar:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\">apt-file search Archivo</code></pre></div>"
    }
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
